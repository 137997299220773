import { Capacitor } from '@capacitor/core'
import type { Style } from '@capacitor/status-bar'
import { StatusBar } from '@capacitor/status-bar'

import { CapacitorPluginNames } from './plugins.util'

type StatusBarSchemeProps = {
  style: Style
  backgroundColor: string
}

const setStatusBarScheme = ({
  style,
  backgroundColor: color,
}: StatusBarSchemeProps) => {
  if (Capacitor.isPluginAvailable(CapacitorPluginNames.StatusBar)) {
    if (Capacitor.getPlatform() === 'android') {
      StatusBar.setStyle({ style })
      StatusBar.setBackgroundColor({ color })
    }
  }
}

export default setStatusBarScheme
